<template>
  <div class="flex text-surface-950 md:justify-center px-safe-4 pt-4 md:pt-24 pb-safe-6">
    <div v-if="$store.state.session.scopableObjectsFetched && !fetching" class="flex flex-col">
      <div class="flex flex-row items-center gap-4 mb-16">
        <p class="text-4xl">Home</p>
        <div v-if="isImpersonating" v-tooltip="'Impersonating another user'" class="flex">
          <icon icon="masks-theater" class="text-2xl" />
        </div>
        <div v-else-if="isSuper" v-tooltip="'Logged in as a super user'" class="flex">
          <SuperIcon size="xl" />
        </div>
      </div>

      <div v-if="companies.length" class="py-4 min-w-[30vw] mb-16">
        <p class="mb-4 text-2xl">Companies</p>
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
          <div v-for="(company, index) in companies" :key="index">
            <home-item-button
              @click.native="() => goCompany(company.route)"
              :text="company.company_name_short"
              :icon="['fas', 'shop']"
            >
            </home-item-button>
          </div>
        </div>
      </div>

      <div v-if="franchisors.length" class="py-4 min-w-[30vw] mb-16">
        <p class="mb-4 text-2xl">Franchises</p>
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
          <div v-for="(franchisor, index) in franchisors" :key="index">
            <home-item-button
              @click.native="() => goFranchisor(franchisor.route)"
              :text="franchisor.franchisor_name_short"
              :icon="['fas', 'buildings']"
            >
            </home-item-button>
          </div>
        </div>
      </div>

      <div v-if="proposals.length" class="py-4 min-w-[30vw] mb-16">
        <p class="mb-4 text-2xl">Proposals</p>
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
          <div v-for="(proposal, index) in proposals" :key="index">
            <home-item-button
              @click.native="() => goQuote(proposal.route)"
              :text="`${proposal.company_name_short} - ${proposal.quote_name}`"
              :icon="['fas', 'briefcase']"
              :showBadge="proposal.quote_is_change_order || proposal.quote_status === 'p'"
            >
            </home-item-button>
          </div>
        </div>
      </div>

      <div v-if="projects.length" class="py-4 min-w-[30vw] mb-16">
        <p class="mb-4 text-2xl">Projects</p>
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
          <div v-for="(project, index) in projects" :key="index">
            <home-item-button
              @click.native="() => goQuote(project.route)"
              :text="`${project.company_name_short} - ${project.quote_name}`"
              :icon="['fas', 'helmet-safety']"
              :showBadge="project.quote_is_change_order || project.quote_status === 'p'"
            >
            </home-item-button>
          </div>
        </div>
      </div>

      <div v-if="vendors.length" class="py-4 min-w-[30vw] mb-16">
        <p class="mb-4 text-2xl">Vendors</p>
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
          <div v-for="(vendor, index) in vendors" :key="index">
            <home-item-button
              @click.native="() => goToProject(vendor.route)"
              :text="`${vendor.vendor_name}`"
              :icon="['fas', 'helmet-safety']"
            >
            </home-item-button>
          </div>
        </div>
      </div>

      <div class="flex flex-col md:flex-row gap-8">
        <div
          class="hover:text-surface-600 max-w-fit transition py-3 pr-3 cursor-pointer"
          @click="signOut"
        >
          <p class="text-2xl underline underline-offset-8 decoration-1">Logout</p>
        </div>
        <div
          class="hover:text-surface-600 max-w-fit transition py-3 pr-3 cursor-pointer"
          @click="goProfile"
        >
          <p class="text-2xl underline underline-offset-8 decoration-1">Profile</p>
        </div>
        <div
          v-if="isSuper"
          class="hover:text-surface-600 max-w-fit transition py-3 pr-3 cursor-pointer"
          @click="goSuper"
        >
          <p class="text-2xl underline underline-offset-8 decoration-1">Super user dashboard</p>
        </div>
        <div
          v-if="isImpersonating"
          class="hover:text-surface-600 max-w-fit transition py-3 pr-3 cursor-pointer"
          @click="stopImpersonating"
        >
          <p class="text-2xl underline underline-offset-8 decoration-1">Stop impersonating</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HomeItemButton from '@/components/ui/home/HomeItemButton.vue'
import BodyMixin from '../mixins/Body'
import UserScope from '../mixins/UserScope'
import UserMeta from '../mixins/UserMeta'
import TranslationMixin from '../quote/presentation/languages/TranslationMixin'
import SuperIcon from '@/components/ui/Icon/SuperIcon.vue'

export default {
  mixins: [BodyMixin, UserScope, UserMeta, TranslationMixin],
  name: 'HomeBody',
  data() {
    return {
      fetching: 0
    }
  },
  computed: {
    proposals() {
      return this.$store.state.session.scopableObjects.quote.filter(
        (q) => q.quote_status === 'p' || q.quote_status === 'd'
      )
    },
    projects() {
      return this.$store.state.session.scopableObjects.quote.filter(
        (q) => q.quote_status !== 'p' && q.quote_status !== 'd'
      )
    },
    vendors() {
      return this.$store.state.session.scopableObjects.vendor || []
    },
    companies() {
      return this.$store.state.session.scopableObjects.company || []
    },
    franchisors() {
      return this.$store.state.session.scopableObjects.franchisor || []
    },
    noScopableObjects() {
      const objs = this.$store.state.session.scopableObjects
      return (
        !objs ||
        ((!objs.quote || !objs.quote.length) &&
          (!objs.company || !objs.company.length) &&
          (!objs.file || !objs.file.length))
      )
    },
    isImpersonating() {
      const session = this.$store.state.session
      const scopedUser = session.scope.user
      const authorizedUser = session.authorizedUser.user_id
      return scopedUser !== authorizedUser
    },
    isSuper() {
      return this.$store.state.session.user.user_is_super_user
    }
  },
  methods: {
    reload() {
      this.fetching = 1
      return this.$store.dispatch('getScopableObjects').finally(() => {
        this.fetching = 0
      })
    },
    async goCompany(scopeRoute) {
      this.$router.push({ name: 'Pipeline', params: { scopeRoute } })
    },
    goQuote(scopeRoute) {
      this.$router.push({ name: 'Project', params: { scopeRoute } })
    },
    goToProject(scopeRoute) {
      this.$router.push({ name: 'Tasks', params: { scopeRoute } })
    },
    goFranchisor(scopeRoute) {
      this.$router.push({ name: 'Franchise Metrics', params: { scopeRoute } })
    },
    goSuper() {
      this.$router.push({ name: 'Super Companies' })
    },
    goProfile() {
      this.$router.push({ name: 'Profile' })
    },
    severityByProjectStatus(project) {
      if (
        (project.quote_status === 'k' ||
          project.quote_status === 'g' ||
          project.quote_status === 'f') &&
        !(project.quote_is_change_order || project.quote_status === 'p')
      ) {
        return 'info'
      } else if (project.quote_is_change_order || project.quote_status === 'p') {
        return 'danger'
      }

      return 'primary'
    },
    signOut() {
      this.$router.push({ name: 'Logout' })
    },
    stopImpersonating() {
      this.$store.dispatch('User/loginAs', {
        object: {
          user_id: this.$store.state.session.authorizedUser.user_id
        }
      })
    }
  },
  components: {
    HomeItemButton,
    SuperIcon
  },
  async created() {
    this.$store.dispatch('modal/closeAll')
  }
}
</script>
